import React from "react";
import { Link } from "gatsby";

const MAX_LENGTH = 160;

const ServiceCardOne = ({ data }) => {
  const { title, text, iconName, url } = data;
  return (
    <div className="icon_box_2 text-center">
      <h3>{title}</h3>
      <p>{`${text.substring(0, MAX_LENGTH)} ...`}</p>
      <div className="iconWrap">
        <i className={iconName}></i>
      </div>
      <Link to={url}>ДЭЛГЭРЭНГҮЙ ...</Link>
    </div>
  );
};

export default ServiceCardOne;
