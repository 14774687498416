import React from "react";

const GoogleMap = ({ extraClass }) => {
  return (
    <div className={`google-map__${extraClass}`}>
      <iframe
        title="template google map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d198.7379560071105!2d106.92063698072981!3d47.91919376384782!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d969246c91a641d%3A0x3763b1d6ebca6f77!2z0KHQuNGC0Lgg0KLQsNGD0Y3RgA!5e0!3m2!1smn!2smn!4v1660736202656!5m2!1smn!2smn"
        className={`map__${extraClass}`}
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default GoogleMap;
